<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col lg="12" md="6">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                <b-card-title>Identification des logs</b-card-title>
              </b-card-header>

              <b-card-body>
                <b-form @submit.prevent="onSubmit">
                  <b-form-group>
                    <b-form-input
                        class="mt-1"
                        v-model="key"
                        type="text"
                        placeholder="Entrez votre clef d'identification"
                    ></b-form-input>
                  </b-form-group>
                  <b-button
                      class="mt-1"
                      type="submit"
                      :disabled="isLoading"
                  ><span v-if="!isLoading">Rechercher votre log</span><span v-if="isLoading">Chargement...</span></b-button>
                </b-form>

              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Log du registre</b-card-title>
            <b-card-sub-title>Une clef d'identification vous est envoyé par mail lors de la soumission d'un formulaire deepform</b-card-sub-title>
          </b-card-header>

          <b-card-body>
            <div>
              <b-card>
                <b-row class="mb-2">
                  <b-col
                      v-for="(key, idx) in Object.keys(logs).filter(x => !hideFields.includes(x))"
                      :key="idx"
                      md="4"
                      class="mb-1"
                  >
                    <strong>{{ key }} : </strong>{{ logs[key] }}
                    <b-button
                        v-if="key === '_certificate'"
                        @click.prevent="downloadCertificate(logs._id)"
                        variant="primary"
                    >
                      Télécharger votre attestation
                    </b-button>
                    <b-button
                        v-if="key === 'deepformResource'"
                        @click.prevent="downloadResource(logs._id)"
                        variant="primary"
                    >
                      Télécharger votre Ressource
                    </b-button>
                    <b-button
                        v-if="key === '_qrcode'"
                        @click.prevent="downloadQRCode(logs._id)"
                        variant="primary"
                    >
                      Télécharger votre QRCode
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
    </b-row>
  </section>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BTable,
  BBadge,
    BFormFile,
  BCardHeader,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import axios from "axios";

import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BForm,
    BFormGroup,
    BButton,
    BFormInput
  },
  data() {
    return {
      key: '',
      logs: {},
      isLoading: false,
      hideFields: ['status', '_showDetails', 'id', '_owner_or_delegate'],

    };
  },
  methods: {
    downloadCertificate(id) {
      axios.get(`https://apisb.openlogs.fr/api/logs/${id}/certificate/accesskey`, {
        headers: {
          "X-OPENLOGS-ACCESSKEY": this.key
        }
      }).then(({ data }) => {
        const a = document.createElement("a");
        a.href = "data:application/octet-stream;base64,"+data.data;
        a.download = `attestation-openlogs.pdf`;
        a.click();
      });
    },
    downloadResource(id) {
      axios.get(`https://apisb.openlogs.fr/api/logs/${id}/deepform/accesskey`, {
        headers: {
          "X-OPENLOGS-ACCESSKEY": this.key
        }
      }).then(({ data }) => {
        const a = document.createElement("a");
        a.href = "data:application/octet-stream;base64,"+ data.data;
        a.download = `resource.${data.extension}`;
        a.click();
      });
    },
    downloadQRCode(id) {
      axios.get(`https://apisb.openlogs.fr/api/logs/${id}/qrcode/accesskey`, {
        headers: {
          "X-OPENLOGS-ACCESSKEY": this.key
        }
      }).then(({ data }) => {
        const a = document.createElement("a");
        a.href = "data:application/octet-stream;base64,"+data.data;
        a.download = `openlogs-qrCode.png`;
        a.click();
      });
    },
    onSubmit(e) {
      this.isLoading = true;
      e.preventDefault();
      if (this.key === '') {
        return;
      }
      axios.get(`https://apisb.openlogs.fr/api/logs/${this.key}`)
          .then(({ data }) => {
            this.logs = data.data;
            this.isLoading = false;
          }).catch((error) => {
            console.log(error)
            this.isLoading = false;
          });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style lang="scss" scoped>
.transaction-item .media {
  align-items: center;
}
</style>
